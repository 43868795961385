<template>
	<Card class="q">
		<h1 slot="title">{{q}}</h1>
		<Row type="flex" justify="space-around" style="text-align: center">
			<Col style="text-align: center;margin: 1em;" v-for="(item, key) in answers"  :key="key">
				<Button type="ghost" shape="circle" size="large" @click='nextQ(key)'>{{item.value}}</Button>
			</Col>
		</Row>
	</Card>
</template>
<script>
	import store from '../store/index.js'
	
	export default{
		name:'q2',
		data(){
			return{
				q:"Που παίρνεις συνήθως το πρωινό σου;",
				answers:[
					{value:"Στο σπίτι",energy:0,nutrition:5},
					{value:"Στον δρόμο",energy:0,nutrition:2},
					{value:"Στην δουλειά",energy:0,nutrition:2},
					{value:"Δεν τρώω πρωινό",energy:0,nutrition:-5}
				]
			}
		},
		methods:{
			nextQ(value){
				
				var response={
					energy:this.answers[value].energy,
					nutrition:this.answers[value].nutrition,
					question:{[this.q]:this.answers[value].value}
				}
				if(value==3){
					response.skipNext = true;
				}
				store.commit('nextQ',response);
			}
		}
	}
</script>
