<template>
	<Card class="q">
		<h1 slot="title">Πόσο εύκολα σηκώνεσαι από το κρεβάτι;</h1>
		<Row>
		<Col span="1">
			<div @click="minus()">&nbsp;</div>
		</Col>
		<Col span="22">
			<Slider :min="1" :max="5" v-model="answer" :tip-format="format" show-stops></Slider>
		<div style="text-align: center;">
			{{format(answer)}}
		</div>
		</Col>
		<Col span="1">
			<div @click="plus()">&nbsp;</div>
		</Col>
		</Row>
		<div style="text-align: right">
			<Button type="success" @click="nextQ">Επόμενο <Icon type="chevron-right"></Icon></Button>
		</div>
	</Card>
</template>
<script>
	import store from '../store/index.js'
	
	export default{
		name:'q1',
		components:{
		},
		data(){
			return{
				q:"Πόσο εύκολα σηκώνεσαι από το κρεβάτι;",
				answer:3,
				values:["Με την καμία!","To snooze είναι φίλος μας","Σηκώνομαι, αυτό έχει σημασία!","Το 'χουμε!","Πριν καν χτυπήσει το ξυπνητήρι"]
			}
		},
		methods:{
			plus(){
				this.answer+=1;
			},
			minus(){
				this.answer-=1;
			},
			nextQ(){
				var response={
					energy : this.answer,
					nutrition: 0,
					question:{[this.q]:this.answer+": "+this.format(this.answer)}
				}
				store.commit('nextQ',response);
			},
			format(val){
				var output = this.values[val-1]
				return output;
			}
		}
	}
</script>
