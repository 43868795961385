<template>
	<Card class="q">
		<h1 slot="title">{{q}}</h1>
		<Row type="flex" justify="space-around" style="text-align: center">
			<Col style="text-align: center;margin: 1em;" v-for="(item, key) in answers" :key="key">
				<Button type="ghost" shape="circle" size="large" @click='nextQ(key)'>{{item.value}}</Button>
			</Col>
		</Row>
	</Card>
</template>
<script>
	import store from '../store/index.js'
	
	export default{
		name:'q4',
		data(){
			return{
				q:"Πόσο συχνά τρως μέσα στην ημέρα;",
				answers:[
					{value:"Τρώω μια και καλή",energy:1,nutrition:-3},
					{value:"Έχω 2 με 3 γεύματα την ημέρα",energy:2,nutrition:-1},
					{value:"Τρώω πολλά μικρά γεύματα",energy:3,nutrition:3},
					{value:"Τσιμπολογάω συνέχεια",energy:2,nutrition:-4}
				]
			}
		},
		methods:{
			nextQ(value){
				var response={
					energy:this.answers[value].energy,
					nutrition:this.answers[value].nutrition,
					question:{[this.q]:this.answers[value].value}
				}
				store.commit('nextQ',response);
			}
		}
	}
</script>
