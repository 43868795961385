<template>
	<Card class="q">
		<h1 slot="title">Τι περιλαμβάνει συνήθως το πρωινό σου;</h1>
		<div style="text-align: center" class="m">
			<Checkbox v-for="(item,k) in answers" v-model="item.checked" :key="k">{{item.value}}
			</Checkbox>
			<div style="display: inline-block; margin-top: 0; margin-bottom: -0.5em">
				<Input v-model="other">
					<span slot="prepend">Άλλο</span>
				</Input>
			</div>
		</div>
		<br>
		<div style="text-align: right">
			<Button type="success" @click="nextQ">Επόμενο <Icon type="chevron-right"></Icon></Button>
		</div>
	</Card>
</template>
<script>
	import store from '../store/index.js'
	
	export default{
		name:'q3',
		components:{
		},
		data(){
			return{
				answers:[
					{value:"Καφές",energy:2,nutrition:0,checked:false},
					{value:"Χυμός",energy:2,nutrition:3,checked:false},
					{value:"Αυγά",energy:0,nutrition:2,checked:false},
					{value:"Δημητριακά",energy:-1,nutrition:-2,checked:false},
					{value:"Ψωμί/Φρυγανιές",energy:-1,nutrition:-2,checked:false},
					{value:"Τυρί",energy:0,nutrition:-1,checked:false},
					{value:"Αλλαντικά",energy:-1,nutrition:-1,checked:false},
					{value:"Ξηροί Καρποί",energy:2,nutrition:0,checked:false},
					{value:"Ταχίνι",energy:2,nutrition:2,checked:false},
					{value:"Μέλι",energy:2,nutrition:2,checked:false},
					{value:"Πίτα/Μπουγάτσα",energy:-1,nutrition:-3,checked:false}
				],
				other:""
			}
		},
		methods:{
			nextQ(value){
				
				var response={
					energy:0,
					nutrition:0,
					question:{"Τι περιλαμβάνει συνήθως το πρωινό σου;":[]}
				}
				this.answers.forEach(function(item){
					if(item.checked){
						response.energy += item.energy;
						response.nutrition += item.nutrition;
						response.question["Τι περιλαμβάνει συνήθως το πρωινό σου;"].push(item.value)
					}
				})
				if(this.other){
					response.question["Τι περιλαμβάνει συνήθως το πρωινό σου;"].push("Άλλο: "+this.other);
				}
/*
				var question = "Τι περιλαμβάνει συνήθως το πρωινό σου;"
				console.log(this.answers)
				
				
				var response = this.answers.reduce(
					function(prev,elem){
						console.log(prev)
						return {
								energy:prev.energy+(elem.checked?elem.energy:0),
								nutrition:prev.nutrition+(elem.checked?elem.nutrition:0),
								question:{
									"Τι περιλαμβάνει συνήθως το πρωινό σου;":
									(elem.checked?
										(prev.question?
											prev.question["Τι περιλαμβάνει συνήθως το πρωινό σου;"]+", ":prev.value)+elem.value
										:(prev.question?
											prev.question["Τι περιλαμβάνει συνήθως το πρωινό σου;"]:""))
								}
							}
					}
				)
*/
// 				console.log(response);
				store.commit('nextQ',response);
			}
		}
	}
</script>
<style scoped>
	div.m > *{
		font-size: 1.2em;
		margin:1em;
	}
</style>