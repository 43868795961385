<template>
	<Card class="q">
		<h1 slot="title">{{q}}</h1>
		<Row type="flex" justify="space-around" style="text-align: center">
			<Col style="text-align: center;margin: 1em;" v-for="(item, key) in answers" :key="key">
				<Button type="ghost" shape="circle" size="large" @click='nextQ(key)'>{{item.value}}</Button>
			</Col>
		</Row>
	</Card>
</template>
<script>
	import store from '../store/index.js'
	
	export default{
		name:'q6',
		data(){
			return{
				q:"Πόσους καφέδες πίνεις την ημέρα;",
				answers:[
					{value:"Δεν πίνω καφέ",energy:0,nutrition:0},
					{value:"Έναν με δυο",energy:-1,nutrition:0},
					{value:"Δυο με τρεις",energy:-2,nutrition:0},
					{value:"Τουλάχιστον 3",energy:-3,nutrition:0}
				]
			}
		},
		methods:{
			nextQ(value){
				var response={
					energy:this.answers[value].energy,
					nutrition:this.answers[value].nutrition,
					question:{[this.q]:this.answers[value].value}
				}
				store.commit('nextQ',response);
			}
		}
	}
</script>