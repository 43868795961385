<template>
	<div id="app" class="layout">
		<Layout>
			<Header>
				<i-circle :percent="complete" :size="60" :stroke-color="color" style="margin-top:3px;" >
				        <Icon v-if="complete == 100" type="ios-checkmark-empty" size="60" style="color:#5cb85c"></Icon>
			    </i-circle>
			</Header>
			<Content style="position:relative;">
				<!--
				<img src="./assets/logo.png">
				<h1>TESTING</h1>
				{{energy}}
				-->
				<quiz />
				<!--
				<i-button @click="slide()">CHANGE QUESTION</i-button>
				<i-button @click="increase(5)">Default</i-button>
				<i-button @click="decrease(2)" type="primary">Primary</i-button>
				<i-button @click="whatAmI" type="primary">Primary</i-button>
				<i-button @click="fetch" type="success">Fetch</i-button>
				-->
			</Content>
			<Footer><a href="pro-fit.gr" target="_blank">Pro-Fit.gr</a></Footer>
		</Layout>
	
	    
	
	   
	</div>
</template>
<script>
import quiz from './components/quiz.vue'
import store from './store/index'

export default {
	name: 'app',
	computed:{
		color () {
            let color = '#2db7f5';
            if (this.complete == 100) {
                color = '#5cb85c';
            }
            return color;
        },
		complete(){
			return store.getters.completed
		}
	},
	methods:{
		whatAmI(){
			var what = store.getters.animal;
			alert(what);
		},
		fetch(){
			store.dispatch('fetchData');
		},
		slide(){
			var result = window.prompt("Question", 1);
			store.commit('setQ',result);
		}
	},
	
	components: {
		quiz
	}
}
</script>

<style>
#app {
	min-height:100vh;
	height:100%;
	display: flex;
	flex-direction: column;
	align-content:center;
	justify-content:center;
}
</style>