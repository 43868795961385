import Vuex from 'vuex'
import Vue from 'vue'
import jquery from 'jquery'

Vue.use(Vuex)
/*eslint no-mixed-spaces-and-tabs: 0 */
/*eslint x-invalid-end-tag: 0 */

export default new Vuex.Store({
	state: {
		energy: 0,
		nutrition:0,
		//REMEBER: ADD TO pro-fit.gr/quiz/animals.json !//
		"bacon":{"name":"bacon","description":"Looks like bacon, smells like bacon, is bacon!","explanation":"asdasdasdamasd adasdasdas dasdasd asdas dasdas dasd a","img":"bacon.jpg"},
		animalArray:[
			[
				{"name":"Βραδύποδας","description":"IS a sloth","explanation":"sloth sloth sloth","img":"bacon.jpg"},
				{"name":"Σκίουρος","description":"Is a squirrel","explanation":"squirrel squirrel squirrel","img":"bacon.jpg"},
				{"name":"Πάντα","description":"panda panda","explanation":"panda panda panda","img":"bacon.jpg"}
			],[
				{"name":"Πάπια","description":"duck duck","explanation":"duck duck duck","img":"bacon.jpg"},
				{"name":"Λιοντάρι","description":"IS a lion","explanation":"lion lion lion lion","img":"bacon.jpg"},
				{"name":"Λεμούριος","description":"IS a lemur","explanation":"lemurlemurlemurlemur","img":"bacon.jpg"}
			],[
				{"name":"Ρακούν","description":"racoon racoon","explanation":"racoon racoon racoon ","img":"bacon.jpg"},
				{"name":"Πιγκουίνος","description":"penguinpenguinpenguin","explanation":"penguin penguin penguin penguin penguin","img":"bacon.jpg"},
				{"name":"Γατόπαρδος","description":"cheetahcheetah","explanation":"cheetah cheetah cheetah cheetah","img":"bacon.jpg"}
			]
		],
		
// 		animalArray:[[sloth,squirrel,panda],[duck,lion,lemur],[racoon,penguin,cheetah]],
		maxdeviation:12,
		done:false,
		activeQ:1,
		totalQ:8,
		interestedIn:'nothing',
		stuff:[]
	},
	getters:{
		animal(state, getters){
			var x = Math.round((state.energy+state.maxdeviation)/state.maxdeviation);
			var y = Math.round((state.nutrition+state.maxdeviation)/state.maxdeviation);
// 			return state.bacon;			
			return state.animalArray[x][y]
		},
		completed(state, getters){
			if(state.totalQ<=state.activeQ){
				return 100;
			} else {
				return (state.activeQ*100)/state.totalQ
			}
		}
	},
	actions:{
		fetchData(state,getters){
			var params = {
				 action:"fetchSponsor"
			}
			jquery.post('http://hblf.local/api/v3.php',params,function(d){console.log(d)})
		}
	},
	mutations: {
		setQ(state,value){
			state.activeQ=value;
		},
		nextQ(state, value){
			this.commit('modE',value.energy)
			this.commit('modN',value.nutrition)
			state.stuff.push(value.question)
			if(value.skipNext){
				state.activeQ+=2
			} else {
				state.activeQ+=1
			}
		},
		modE(state,value){
			state.energy+=value
			if(state.energy>state.maxdeviation){
				state.energy=state.maxdeviation
			}
		},
		modN(state,value){
			state.nutrition+=value
			if(state.nutrition>state.maxdeviation){
				state.nutrition=state.maxdeviation
			}
		},
		askHelp(state,value){
			state.interestedIn = value
			state.activeQ = 10
			state.done = true
		},
		backToResults(state){
			state.activeQ = 8
		},
		increment (state, value) {
			state.energy+=value
			if(state.energy>state.maxdeviation){
				state.energy=state.maxdeviation
			}
		},
		decrease(state,value){
			state.energy-=value
			if(state.energy<-state.maxdeviation){
				state.energy=-state.maxdeviation
			}
		}
	}
})