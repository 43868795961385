<template>
	<Card class="q">
		<h1 slot="title">Η ευεξία σου είναι {{animal.name}}</h1>
		<Row :gutter="16">
			<Col :xs="24" :md="12">
				<img v-if="ready" :src="getImgUrl(animal.img)" :alt="animal.name" width="100%">
			</Col>
			<Col :xs="24" :md="12">
				<h2>{{animal.description}}</h2>			
				<p>{{animal.explanation}}</p>
				<div style="text-align: left; margin:1em">
					<h3>Μοιράσου το:
						<ButtonGroup>
							<Button type="ghost" @click="share(fburl)" title="Μοιράσου το στο Facebook">&nbsp;<Icon type="social-facebook" size="20" color="#3B5998"/>&nbsp;</Button>
					        <Button type="ghost" @click="share(twitterurl)" title="Μοιράσου το στο Twitter"><Icon type="social-twitter" size="20" color="#55acee"/></Button>
					        <Button type="ghost" @click="share(redditurl)" title="Μοιράσου το στο Reddit"><Icon type="social-reddit" size="20" color="#ff5700"/></Button>
					        <Button type="ghost" @click="share(email)" title="Μοιράσου το με email"><Icon type="email" size="20" color="#444444"/></Button>
						</ButtonGroup>
					</h3>
				</div>
				<div v-if="done" style="text-align: right">
					<Button type="success" @click="help">Επόμενο <Icon type="chevron-right"></Icon></Button>
				</div>
			</Col>
		</Row>
		
		<div v-if="!done">
			<h1>Εάν μπορούσες να βελτιώσεις κάτι, τι θα ήθελες να βελτιώσεις;</h1>
			<Button @click="help('energy')">Περισσότερη ενέργεια</Button>
			<Button @click="help('weightloss')">Να χάσω μερικά κιλά</Button>		
			<Button @click="help('weightgain')">Να αποκτήσω γράμμωση και να βάλω μυϊκή μάζα</Button>
			<Button @click="help('money')">Περισσότερα χρήματα</Button>
		</div>
	</Card>
</template>
<script>
	import store from '../store/index.js'
	
	export default{
		name:'qD',
		data(){
			return{
				animal:{name:"",description:"",explanation:"",img:""},
				fburl:"",
				twitterurl:"",
				redditurl:"",
				email:"",
				ready:false
			}
		},
		computed:{
			done(){
				return store.state.done
			}	
		},
		mounted(){
			this.animal = store.getters.animal;
			
			this.fburl = 'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent('https://pro-fit.gr/quiz/?r='+this.animal.name);
			
			this.twitterurl = 'https://twitter.com/intent/tweet?text=Η%20ευεξία%20μου%20είναι%20'+this.animal.name+'&url=https%3A%2F%2Fpro-fit.gr%2Fquiz%2F%3Fr%3D'+this.animal.name+'&hashtags=wellness&via=ProFitGreece'
			this.redditurl = 'http://reddit.com/submit?url='+encodeURIComponent('https://pro-fit.gr/quiz/?r='+this.animal.name)+'&title=Η%20ευεξία%20μου%20είναι%20'+this.animal.name
			this.email="mailto:?subject=Η ευεξία μου είναι "+this.animal.name+". Εσένα;&body="+this.animal.description+"%0A"+this.animal.explanation+"%0A%0A"+escape("https://pro-fit.gr/quiz/?r="+this.animal.name)
			
			this.ready = true
		},
		methods:{
			help(value){
				store.commit('askHelp',value);
			},
			getImgUrl(pic) {
			    return require('../assets/'+pic)
			},
			share(link){
				window.open(link, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
			}
		}
	}
</script>
<style scoped>
	h2{
		margin-bottom: 1em;
	}
</style>