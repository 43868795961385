<template>
	<Card class="q">
		
		<h1 slot="title">Θες να βελτιώσεις την κατάσταση σου;</h1>
		<h3 style="text-align: center">Άσε μας τα στοιχεία σου και θα επικοινωνήσουμε μαζί σου άμεσα!</h3>
		<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="130">
	        <FormItem label="Όνομα" prop="name">
	            <Input v-model="formValidate.name" placeholder="Enter your name"></Input>
	        </FormItem>
	        <FormItem label="Τηλέφωνο" prop="phone">
	            <Input type="tel" v-model="formValidate.phone" placeholder="Enter your phone"></Input>
	        </FormItem>
	        <FormItem label="E-mail" prop="mail">
	            <Input v-model="formValidate.mail" placeholder="Enter your e-mail"></Input>
	        </FormItem>
	        
	        <FormItem label="Ώρες επικοινωνίας" prop="time">
	            <TimePicker v-model="formValidate.time" type="timerange" placement="bottom" :disabled-hours="[0,1,2,3,4,5,6,7,23,24]" hide-disabled-options placeholder="Ώρες επικοινωνίας" format="HH:mm" :steps="[1, 15]"></TimePicker>
	        </FormItem>
	        <FormItem>
	            <Button type="primary" @click="handleSubmit('formValidate')">Αποστολή</Button>
<!-- 	            <Button type="ghost" @click="handleReset('formValidate')" style="margin-left: 8px">Reset</Button> -->
	        </FormItem>
		</Form>
			<Button @click="back"><Icon type="chevron-left"></Icon> Πίσω</Button>
	</Card>
</template>
<script>
	import store from '../store/index.js'
	import jquery from 'jquery'
	
	export default{
		name:'Help',
		data(){
			return{
				interestedIn:'nothing',
				formValidate: {
                    name: '',
                    phone:'',
                    mail: '',
                    time: ''
                },
                ruleValidate: {
                    name: [
                        { required: true, message: 'Εισάγετε το όνομα σας', trigger: 'blur' }
                    ],
                    phone:[
	                    { required: true, message: 'Εισάγετε τον αριθμό τηλεφώνου σας', trigger: 'blur'},
	                    { type: 'string', pattern: /^[\+,0]?[0-9]{10,12}$/, min:10, max:13, message: 'Εισάγετε έναν έγκυρο αριθμό τηλεφώνου', trigger: 'blur'}
                    ],
                    mail: [
                        { required: true, message: 'Το email δεν μπορεί να είναι κενό', trigger: 'blur' },
                        { type: 'email', message: 'Εισάγετε μια έγκυρη διεύθυνση email', trigger: 'blur' }
                    ],
                    time: [
                        { required: true, type: 'array', message: 'Επιλέξτε την ώρα που σας βολεύει για να σας καλέσουμε', trigger: 'change',
						    fields: {
						      0: {type: 'string', required: true, message: 'Επιλέξτε την ώρα που σας βολεύει για να σας καλέσουμε', trigger: 'change'},
						      1: {type: 'string', required: true, message: 'Επιλέξτε την ώρα που σας βολεύει για να σας καλέσουμε', trigger: 'change'} 
						    }
					    }
                    ]
                }
			}
		},
		mounted(){
			this.interestedIn = store.state.interestedIn
			this.animal = store.getters.animal;
			
			this.fburl = 'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent('https://pro-fit.gr/quiz/?r='+this.animal.name);
			
			this.twitterurl = 'https://twitter.com/intent/tweet?text=Η%20ευεξία%20μου%20είναι%20'+this.animal.name+'&url=https%3A%2F%2Fpro-fit.gr%2Fquiz%2F%3Fr%3D'+this.animal.name+'&hashtags=wellness&via=ProFitGreece'
			this.redditurl = 'http://reddit.com/submit?url='+encodeURIComponent('https://pro-fit.gr/quiz/?r='+this.animal.name)+'&title=Η%20ευεξία%20μου%20είναι%20'+this.animal.name
			this.email="mailto:?subject=Η ευεξία μου είναι "+this.animal.name+". Εσένα;&body="+this.animal.description+"%0A"+this.animal.explanation+"%0A%0A"+escape("https://pro-fit.gr/quiz/?r="+this.animal.name)
		},
		methods:{
			handleSubmit (name) {
                this.$refs[name].validate((valid) => {
                    if (valid) {
	                    var message = (this.$Message);
						var params = {
							 action:"quiz-contact",
							 name:this.formValidate.name,
							 email:this.formValidate.mail,
							 phone:this.formValidate.phone,
							 time:this.formValidate.time,
							 interest:store.state.interestedIn
						}
	                    console.log(params);
						jquery.post('/api/v3.php',params,function(d){
							console.log(d)
							if(d.msg=="ok"){
		                        message.success('Success!');
	                        } else {
		                        message.info(d.msg);
	                        }
                        })
						.fail(function(d) {
							message.error( "Σφάλμα! Δοκιμάστε ξανά αργότερα." );
						})
                    } else {
                        this.$Message.error('Παρακαλώ επιβεβαιώστε τα στοιχεία σας!');
                    }
                })
            },
			getImgUrl(pic) {
			    return require('../assets/'+pic)
			},
			share(link){
				window.open(link, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
			},
			back(){
				store.commit('backToResults');
			}
		}
	}
</script>
<style scoped>
	h2{
		margin-bottom: 1em;
	}
</style>