<template>
	<Row type="flex" justify="center" align="middle" style="position: absolute;height:100%;width:100%;">
		<Col :xs="24" :md="20" :lg="16" :xl="12" class="quiza">
			<Row>
				<transition name="slide-fade" mode="out-in">
					<Col v-if="active==1" key="1">
						<q1/>
					</Col>
					<Col v-if="active==2" key="2">
						<q2/>
					</Col>
					<Col v-if="active==3" key="3">
						<q3/>
					</Col>
					<Col v-if="active==4" key="4">
						<q4/>
					</Col>
					<Col v-if="active==5" key="5">
						<q5/>
					</Col>
					<Col v-if="active==6" key="6">
						<q6/>
					</Col>
					<Col v-if="active==7" key="8">
						<q8/>
					</Col>
					<Col v-if="active==8" key="done">
						<qD/>
					</Col>
					<Col v-if="active==10" key="help">
						<Help/>
					</Col>
				</transition>
			</Row>
		</Col>
	</Row>
</template>
<script>
	import Help from './Help.vue'
	import q1 from './Q1.vue'
	import q2 from './Q2.vue'
	import q3 from './Q3.vue'
	import q4 from './Q4.vue'
	import q5 from './Q5.vue'
	import q6 from './Q6.vue'
// 	import q7 from './Q7.vue'
	import q8 from './Q8.vue'
	import qD from './QD.vue'
	import store from '../store/index.js'

	export default{
		name:'quiz',
		components:{
			Help,
			q1,q2,q3,q4,q5,q6,q8,
			qD
		},
		computed:{
			active(){
				return store.state.activeQ
			}
		}
	}
</script>
<style>
	.slide-fade-enter-active, .slide-fade-leave-active {
		transition: all 0.4s ease-in-out;
	}
	
	.slide-fade-enter{
		transform: translateX(10%);
		opacity: 0;
	} 
	.slide-fade-leave-to
	/* .slide-fade-leave-active below version 2.1.8 */ {
		transform: translateX(-10%);
		opacity: 0;
	}
	.ivu-card-head > h1{
		text-align: center;
	}
</style>